<template>
  <v-card-title class="flex-column align-stretch">

    <!--  이미지: 로고  -->
    <v-img
      :src="gcCommon_refund.event_image_refund_src"
      aspect-ratio="3"
      class="mt-n4 mx-n4 mb-2"
      max-width="640"
      width="calc(100% + 32px)"
      contain
    />

    <!--  텍스트: 행사 제목  -->
    <h1
      v-if="gcCommon_refund.event"
      v-text="gcCommon_refund.event.event_title"
      class="text-h6 text-center word-break-keep-all"
    />

    <div class="subtitle-2 text-center">
      <!--  텍스트: 날짜  -->
      <div v-text="gcCommon_refund.refund_event_period"/>
      <!--  텍스트: 주소  -->
      <div v-text="gcCommon_refund.refund_address"/>
    </div>

    <v-divider class="mt-4 mb-3"/>

  </v-card-title>
</template>

<script>
export default {
  name: "SubmitCardTitle"
}
</script>