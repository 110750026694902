<template>
  <v-sheet
    width="280"
    color="transparent"
  >
    <v-card class="mt-8">
      <!--  이미지: 이비서 로고  -->
      <v-card-title class="pt-8 pb-6">
        <v-img
          :src="require('../assets/logo.png')"
          class="mx-auto"
          width="200"
          max-width="200"
          contain
        />
      </v-card-title>

      <!--  그룹: form  -->
      <v-form
        v-model="form"
        class="pa-4 pb-0"
        ref="form"
        :disabled="gcCommon_requesting"
      >
        <v-input
          v-model="code"
          :rules="[required]"
          validate-on-blur
        >
          <v-otp-input
            @keydown.ctrl.prevent
            @blur="onBlur"
            v-model="code"
            type="number"
            :length="6"
            hide-spin-buttons
          />
        </v-input>

        <p class="text-center subtitle-2 grey--text text--darken-1">
          안내 받은 행사코드를 입력하세요
        </p>
      </v-form>

      <!--  그룹: 버튼  -->
      <v-card-actions class="px-4 pt-6 pb-10 justify-center white">
        <v-btn
          @click="onClickEnter"
          ref="submit"
          class="font-weight-bold text-h6"
          type="submit"
          color="blue"
          :loading="gcCommon_requesting"
          large
          block
          dark
        >입장하기</v-btn>
      </v-card-actions>

    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "Home",

  mounted() {
    this.gmSubmit_init()
  },

  data: () => ({
    // 입력코드
    code: '',
    // 폼 유효성 검사 결과
    form: null,
  }),

  computed: {
    required() {
      return value => value?.length === 6 || '코드를 확인해주세요'
    }
  },

  methods: {
    onBlur() {
      if (this.code.length >= 6) this.onClickEnter()
    },
    // 입장 버튼 클릭시
    async onClickEnter() {
      if (this.validate()) {
        await this.$router.replace({
          name: 'SubmitPublic',
          params: { refund_public_code: this.code }
        })
      }
    },

    // 코드 유효성 검사
    validate() {
      const valid = this.$refs.form.validate()

      // 유효하지 않을 경우
      !valid && document.querySelectorAll('.v-otp-input>.v-input')
        .forEach(el => {
          el.classList.add('v-input--has-state', 'error--text')
        })

      return valid
    }
  }
}
</script>