export default {
  // 페이지 로딩 완료
  gsCommon_pageLoaded: false,
  // 요청중
  gsCommon_requesting: false,

  // 서비스 가동 여부
  gsCommon_serviceState: {
    content: '',
    service_paused: -1,
  },

  // 환급 요청 관련 정보 객체
  gsCommon_refund: {},
  gsCommon_refundUser: {},

  // 애러 정보 객체
  gsCommon_infoError: {
    show: false,
    code: '',
    message: ''
  },

  // 알림창 객체
  gsCommon_alert: {
    width: 0,
    text: '',
    confirmOkText: '',
    confirmOkColor: '',
    confirmNoText: '',
    confirmNoColor: '',
    defaultOkText: '',
    defaultOkColor: '',
    confirm: false,
    callback: null,
  },

  // html 팝업 객체
  gsCommon_alertHtml: '',

  // 다이알로그 리스트 노출 여부 관리 객체
  gsCommon_dialogShow: {
    alert: false,
    alertHtml: false,
    img: false,
    imgType: false,
  },

  // 다이알로그 닫아야 할 스택
  gsCommon_dialogStack: [
    // 'alert', 'imgType', ...
  ]
}