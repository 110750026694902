import { state } from '../store'

export default {
  gcSubmit_refundSubmitState: {
    get() { return state.gsSubmit_formData.refund_submit_state },
    set(val) { state.gsSubmit_formData.refund_submit_state = val },
  },
  gcSubmit_submitting() {
    return this.$store.state.gsSubmit_submitting
  },
  gcSubmit_submitted() {
    return this.$store.state.gsSubmit_submitted
  },
  gcSubmit_removeMode() {
    return this.$store.state.gsSubmit_removeMode
  },
  gcSubmit_formData() {
    return this.$store.state.gsSubmit_formData
  },
  gcSubmit_form() {
    return this.$store.state.gsSubmit_form
  },
}