export default {
  // 양식 제출중
  gsSubmit_submitting: false,

  // 양식 제출 함
  gsSubmit_submitted: false,

  // 폼 데이터
  gsSubmit_formData: {
    // event id
    event_id: 0,
    // 환급 요청 (0: 선택안함, 1: 요청, 2: 미요청)
    refund_submit_state: 0,
    // 참가자 회사명(병원명)
    refund_user_company: '',
    // 참가자 병원 상세 주소
    refund_starting_address: '',
    // 참가자 병원 상세 주소 타입 (0: 병원, 1: 자택)
    refund_starting_address_type: 0,
    // 참가자 이름
    refund_user_name: '',
    // 참가자 연락처
    refund_user_phone: '',
    // 참가자 부서
    refund_user_part: '',
    // 참가자 서명 (base64)
    participant_sign: '',
    // 은행명 (직접입력 시)
    refund_bank_name: '',
    // 은행 코드
    refund_bank_code: '',
    // 계좌번호
    refund_account_number: '',
    // 예금주
    refund_account_holder: '',
    // 업로드 될 파일 객체 리스트
    files_upload: [
      //{
      //  file: File_Object,
      //  type: 0, // 0: 기타, 1: 숙박비, 2: 등록비, 3: 식대, 4: 교통비
      //  memo: ''
      //}
    ],
    // 업데이트 될 파일 객체 리스트
    files_update: [
      //{
      //  id: 0, // 해당 파일 아이디
      //  type: 0, // 바뀔 타입
      //  memo: '' // 바뀔 메모
      // }
    ],
    // 삭제 될 파일 아이디 리스트
    files_remove: [ /* number */ ],
    // 커스텀 입력 필드 리스트
    refund_user_custom_field: [
      // {
      //   id: ~0, // 0: 생성, 1: 수정
      //   value: '', // 입력한 값
      //   label: '', // 서버저장 x 라벨 값
      //   refund_custom_field_id: 1, // REFUND_CUSTOM_FIELD.id
      // }
    ]
  },

  // 업로드 이미지 리스트에서 삭제모드
  gsSubmit_removeMode: false,

  // 폼 글로벌 레퍼
  gsSubmit_form: {
    ref: null,
    valid: false,
    // 터치 무브 이벤트 핸들러 카운트 숫자
    // - 재대로 사인 했는지 여부 판단을 위한것임
    signLength: 0,
    invalidSignText: ''
  },
}