import Vue from 'vue'
import Vuex from 'vuex'

import stateCommon from "./stateCommon"
import stateSubmit from "./stateSubmit"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // 네이밍 규칙
    // gs{Type}_{nameName}
    ...stateCommon,
    ...stateSubmit,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

export default store
export const state = store.state