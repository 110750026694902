import store from "../store/index"
import router from "./router"
import axios from '@/plugins/axios'

router.beforeEach(async(to, from, next) => {
  try {
    const { code, data } = await axios({
      method: 'GET',
      url: `/api/v1/service_paused`,
    })
    if (code === 200) {
      store.state.gsCommon_serviceState.content = data.content
      store.state.gsCommon_serviceState.service_paused = data.service_paused
    }
    else {
      store.state.gsCommon_serviceState.content = '서비스 애러'
      store.state.gsCommon_serviceState.service_paused = 1
    }
  }
  catch(error) {
    console.error(error)
    store.state.gsCommon_serviceState.content = '서비스 애러'
    store.state.gsCommon_serviceState.service_paused = 1
  }

  // 첫 방문인데 방문 경로에 해시가 있을 경우 초기화
  // - '#' 와 같이 값이 없는 해시일 경우를 캐치하기 위해 정규식을 사용
  if (from.name === null && Boolean(location.href.match('#'))) {
    location.href = location.href.replace(/#.*/, '')
    return
  }

  const dialogStack = store.state.gsCommon_dialogStack
  const dialogStackLength = dialogStack.length

  // 스택 값이 있고 해시 추가의 경우
  if (dialogStackLength === 1 && to.hash === '#dialog' && from.hash === '') {
    return next()
  }
  // 다이알로그 스택이 있고 모바일 백버튼 클릭 시
  if (dialogStackLength && to.hash === '' && from.hash === '#dialog') {
    const key = dialogStack[dialogStackLength - 1]
    // 시스템 알림창이 활성화 상태일 경우
    if (key === 'alert') {
      return next(false)
    }
    // 그렇지 않을 경우 처리
    else {
      store.state.gsCommon_dialogShow[key] = false
      store.state.gsCommon_dialogStack.pop()

      return dialogStack.length === 0 ? next() : next(false)
    }
  }

  next()
})