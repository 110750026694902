import axios from "../plugins/axios"
import { state } from "../store"
import env from "../env"

export default {

  // 서브밋: 환급 요청
  async gmAPI_submitRefund() {
    state.gsSubmit_submitting = true

    let success = false
    const ref = this.gcSubmit_formData
    const data = {
      participant_sign: ref.participant_sign,
      id: this.gcCommon_refundUser?.id || 0,
      event_id: ref.event_id,
      refund_user_company: ref.refund_user_company,
      refund_starting_address: ref.refund_starting_address,
      refund_starting_address_type: ref.refund_starting_address_type,
      refund_user_part: ref.refund_user_part,
      refund_user_name: ref.refund_user_name,
      refund_user_phone: ref.refund_user_phone,
      refund_account_holder: ref.refund_account_holder,
      refund_account_number: ref.refund_account_number,
      refund_bank_code: ref.refund_bank_code,
      refund_bank_name: ref.refund_bank_name,
      refund_submit_state: ref.refund_submit_state,
      refund_user_custom_field: JSON.parse(JSON.stringify(ref.refund_user_custom_field)),
    }

    // 기본 정보 업데이트 API
    const refund_user_id = (await axios({
      method: 'POST',
      url: `/api/v1/submit/receipt_value`,
      data
    })).data // refund_user_id

    // 기본 정보 업데이트 성공 || 개발자 모드
    if (refund_user_id || env.DEV_MODE) {
      const data = new FormData()
      let index = 0

      data.append('refund_user_id', refund_user_id)

      // 삭제 리스트 추출
      ref.files_remove.forEach(id => {
        data.append(`id[${ index }]`, id)
        data.append(`receipt_remove[${ index }]`, '1')
        data.append(`receipt_memo[${ index }]`, ``)
        data.append(`file[${ index }]`, '-1')
        index++
      })

      // 업데이트 리스트 추출
      ref.files_update.forEach(obj => {
        data.append(`id[${ index }]`, obj.id)
        data.append(`receipt_remove[${ index }]`, '0')
        data.append(`receipt_memo[${ index }]`, obj.receipt_memo)
        data.append(`file[${ index }]`, 'null')
        index++
      })

      // 업로드 리스트 추출
      ref.files_upload.forEach(obj => {
        data.append(`id[${ index }]`, '0')
        data.append(`receipt_remove[${ index }]`, '0')
        data.append(`receipt_memo[${ index }]`, obj.receipt_memo)
        data.append(`file[${ index }]`, obj.file)
        index++
      })

      const skip_able =
        (ref.files_remove.length +
        ref.files_update.length +
        ref.files_upload.length) === 0
      success = skip_able || await axios({
        method: 'POST',
        url: `/api/v1/submit/receipt_files`,
        data
      })
    }

    state.gsSubmit_submitting = false
    this.$store.state.gsSubmit_submitted = Boolean(success)
  }
}
