import { state } from "../store"
import axios from "../plugins/axios"
import env from "../env"

import Home from "../views/Home"
import Submit from "../views/Submit"
import Paused from "../views/Paused"

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter(to, from, next) {
      // 시스템 이벤트 핸들러 해제: 페이지 이탈 경고
      onbeforeunload = null

      state.gsCommon_pageLoaded = true
      next()
    }
  },
  {
    path: '/service-paused',
    name: 'ServicePaused',
    component: Paused,
    beforeEnter(to, from, next) {
      state.gsCommon_serviceState.service_paused !== 0 ? next() : next('/')
    }
  },
  {
    path: '/public/:refund_public_code',
    name: 'SubmitPublic',
    component: Submit,
    async beforeEnter(to, from, next) {
      if (state.gsCommon_serviceState.service_paused !== 0) {
        return next('/service-paused')
      }

      const id = to.params.refund_public_code

      if (!id) { return next('/') }

      state.gsCommon_requesting = true
      const result = (await axios({
        method: 'GET',
        url: `/api/v1/submit/public/${ id }`,
      }))
      state.gsCommon_requesting = false

      if (!result) { return next(false) }
      else if (result.code === 411) {
        alert('행사 코드에 해당하는 정보가 없습니다.')
        return next(false)
      }
      else if (result.code === 413) {
        alert('본 행사는 더 이상 입장이 불가능 합니다.')
        return next(false)
      }

      onbeforeunload = env.DEV_MODE ? null : () => '현재 페이지에서 나가시겠습니까?'

      const data = result.data
      state.gsCommon_refund = data
      state.gsCommon_refundUser = {}
      state.gsSubmit_formData.event_id = data.event.id
      state.gsSubmit_formData.refund_user_custom_field =
        data.refund_custom_field?.map(item => {
          return {
            id: 0,
            value: '',
            label: item.label,
            refund_custom_field_id: item.id,
          }
        }) || []

      state.gsCommon_pageLoaded = true
      next()
    }
  },
  {
    path: '/:refund_user_id',
    name: 'SubmitPrivate',
    component: Submit,
    async beforeEnter(to, from, next) {
      if (state.gsCommon_serviceState.service_paused !== 0) {
        return next('/service-paused')
      }

      const id = to.params.refund_user_id

      if (!id) { return next('/') }

      const data = (await axios({
        method: 'GET',
        url: `/api/v1/submit/private/${ id }`,
      })).data || {}

      if (!data.refund_user) { return next(false) }

      onbeforeunload = env.DEV_MODE ? null : () => '현재 페이지에서 나가시겠습니까?'

      state.gsCommon_refund = data.refund
      state.gsCommon_refundUser = data.refund_user
      state.gsSubmit_formData.event_id = data.refund.event.id
      state.gsSubmit_formData.refund_user_custom_field =
        data.refund_user.refund_user_custom_field?.map(item => {
          return {
            id: item.id,
            value: item.value,
            label: item.label,
            refund_custom_field_id: item.refund_custom_field_id,
          }
        }) || []

      state.gsCommon_pageLoaded = true
      next()
    }
  },
  {
    path: '*',
    beforeEnter(to, from, next) {
      next('/')
    }
  }
]