import env from '../../env'

// 헨들러: HTTP method status === 200 이지만 사전 약속된 서버 커스텀 애러처리
function responseError(response) {
  // API 완성 이후 리스트 업 해서 코드별 사유가 다를 수 있으니
  //   조건문으로 필터해서 바디 텍스트 입력 해야함.
  //   (같은코드에 사유가 다를 수 있고, 서버에서는 사유를 영어로만 내려줌)
  const code = response.data.code
  const message = response.data.data
  const url = response.config.url
  const nonErrorObject = {
    '/api/v1/submit/public': [411, 413]
  }
  console.error('responseError: ', response)

  const customPass = !Object.keys(nonErrorObject).every(key => {
    const codeArray = nonErrorObject[key]
    return !(url.startsWith(key) &&
      codeArray.length &&
      codeArray.indexOf(code) !== -1)
  })

  // 애러 출력없이 종료
  if (customPass) { return response.data }

  const body = `Error: ${ message }\nCode: ${ code }`

  env.DEV_MODE && console.error('responseError: ', response)
  !env.DEV_MODE && alert(body)
}

// 헨들러: HTTP method status === 200 응답처리
export const response = response => {
  // 설명: 예외처리에서 제외되는 응답 해더 컨텐츠 타입 리스트
  const validType = [
  ]

  // 조건문: 서버와 사전 약속된 응답코드가 200 (정상응답) 이다
  if (response.data?.code === 200) {
    return response.data || true
  }
  // 조건문: 서버와 사전 약속된 응답 해더 컨텐츠 타입이다
  else if (validType.indexOf(response.headers['content-type']) !== -1) {
    return true
  }
  else {
    return responseError(response)
  }
}

// 헨들러: HTTP method status 애러처리 (404, 500 등)
// 참고: 이 헨들러가 호출된 이후에 axios.catch 실행됨
export const error = error => {
  const code = error?.response?.status || 'undefined(maybe CORS)'
  const url = error?.config?.url || 'undefined'
  const body = `일시적인 현상일 수 있으니 잠시 후 다시 시도해 주십시오. \n 지속적으로 이러한 현상이 나타날 경우 서비스 공급처에 문의하십시오. \n\nCode: ${code} \nURL: ${url}`

  !env.DEV_MODE && alert(body)
}