export default {
  gcCommon_pageLoaded() {
    return this.$store.state.gsCommon_pageLoaded
  },
  gcCommon_serviceState() {
    return this.$store.state.gsCommon_serviceState
  },
  gcCommon_requesting() {
    return this.$store.state.gsCommon_requesting
  },
  gcCommon_refund() {
    return this.$store.state.gsCommon_refund
  },
  gcCommon_refundUser() {
    return this.$store.state.gsCommon_refundUser
  },
  gcCommon_infoError() {
    return this.$store.state.gsCommon_infoError
  },
  gcCommon_alert() {
    return this.$store.state.gsCommon_alert
  },
  gcCommon_alertHtml() {
    return this.$store.state.gsCommon_alertHtml
  },
}