<template>
  <v-card-text class="pt-10 pb-16 black--text text-center">
    <h2 class="pb-4">지금은 제출 기간이 아닙니다.</h2>
    <p>기타 문의사항은 담당자 연락처로 부탁드립니다.</p>
  </v-card-text>
</template>

<script>
export default {
  name: 'SubmitCardEnded'
}
</script>