<template>
  <v-app>
    <!--  상태: 페이지 로딩  -->
    <v-progress-linear
      v-if="!gcCommon_pageLoaded"
      indeterminate
    />

    <!--  레퍼: 컨텐츠  -->
    <v-main>
      <v-container :class="classList">

        <!--  서비스 애러 뷰어  -->
        <error v-if="gcCommon_infoError.show"/>

        <!--  라우터 뷰어  -->
        <router-view v-else/>

        <!--  시스템: 알림창  -->
        <common-alert/>

        <!--  시스템: html 팝업창  -->
        <common-alert-html/>

      </v-container>
    </v-main>

    <v-footer
      v-if="$route.name === 'Home'"
      class="subtitle-2 justify-center"
      color="transparent"
    >
      © {{ year }}. Inowing Inc. all rights reserved.
    </v-footer>

  </v-app>
</template>

<script>
import "./sass/overrides.sass"

import CommonAlert from "./components/CommonAlert"
import CommonAlertHtml from "./components/CommonAlertHtml"
import Error from "./views/Error"

export default {
  name: 'App',

  components: {
    CommonAlert,
    CommonAlertHtml,
    Error,
  },

  watch: {
    'gcSubmit_submitting'(value) {
      // 전송여부에 따른 웹 페이지 스크롤링 토글
      document.body.style.overflow = value ? 'hidden' : ''
    }
  },

  computed: {
    // 라우터에 따른 클레스 리스트 리턴
    classList() {
      const name = this.$route.name
      const positionStartPageList = [
        'SubmitPublic',
        'SubmitPrivate'
      ]
      const needStartPosition = positionStartPageList.indexOf(name) !== -1
      const align = needStartPosition ? 'align-start' : 'align-center'

      return `pa-0 fill-height justify-center ${ align }`
    },

    year() {
      return new Date().getFullYear()
    }
  }
}
</script>