<template>
  <div
    id="upload-component"
    class="pb-5"
  >
    <v-divider class="mt-1 mb-6"/>

    <!--  텍스트: 안내문구  -->
    <p class="text-body-2 font-weight-bold mb-6 blue--text">
      - 영수증 이미지를 첨부해주세요.(최대 12장) <br>
      - 사진 촬영시 1장의 영수증만 촬영해주세요. <br>
      - 업로드된 이미지를 탭하면 메모를 남기실 수 있습니다.
    </p>

    <!--  레퍼: 그리드 레이아웃  -->
    <div class="c-grid-container mb-4">
      <!--  업로드 리스트 아이템  -->
      <v-card
        v-for="(data, i) in imgList"
        :key="`img-${i}`"
        :class="`c-img-card pa-1 ${isCheckImg(i) ? 'checked' : ''}`"
        @click="onClickImgOpen(data, i)"
        :disabled="gcSubmit_submitting"
        outlined
      >
        <!--  이미지  -->
        <v-img
          width="100%"
          aspect-ratio="1"
          :src="data.receipt_src"
          contain
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                v-if="data.receipt_src"
                indeterminate
                color="grey"
              />
              <v-col v-else class="text-center word-break-keep-all">영수증 이미지 없음</v-col>
            </v-row>
          </template>
          <template v-slot:default>
            <v-avatar
              :color="data.receipt_memo ? 'rgba(255,255,255,.75)' : null"
              size="32"
            >
              <v-icon v-if="data.receipt_memo">mdi-message-text-outline</v-icon>
            </v-avatar>
          </template>
        </v-img>

        <!--  아이콘: 아이템 삭제 예정 표기  -->
        <v-icon
          v-if="gcSubmit_removeMode"
          :color="isCheckImg(i) ? 'red' : ''"
          class="c-check-icon"
          large
        >{{isCheckImg(i) ? 'mdi-checkbox-marked-circle' : 'mdi-circle-outline'}}</v-icon>
      </v-card>
    </div>

    <!--  인풋: 멀티 이미지 파일 업로드  -->
    <input
      @change="onInputFiles"
      ref="input"
      type="file"
      accept="image/jpeg,image/png,image/webp"
      multiple
      hidden
    >

    <!--  버튼 그룹: 삭제 모드  -->
    <template v-if="gcSubmit_removeMode">
      <!--  버튼: 삭제 취소  -->
      <v-btn
        @click="onClickCancel"
        :disabled="gcSubmit_submitting"
        outlined
        block
      >
        <v-icon>mdi-close</v-icon>
        <span class="text-h6 font-weight-bold">취소</span>
      </v-btn>
      <!--  버튼: 삭제 적용  -->
      <v-btn
        @click="onClickRemove"
        class="mt-4"
        color="green"
        :disabled="!enabledApplyButton"
        :dark="enabledApplyButton"
        depressed
        block
      >
        <v-icon>mdi-check</v-icon>
        <span class="text-h6 font-weight-bold">적용</span>
      </v-btn>
    </template>

    <!--  버튼 그룹: 업로드 모드  -->
    <template v-else>
      <!--  버튼: 이미지 추가  -->
      <v-btn
        @click="onClickAddType(0)"
        :disabled="!enabledAddButton"
        color="primary"
        depressed
        block
      >
        <v-icon>mdi-plus</v-icon>
        <span class="text-h6 font-weight-bold">영수증 추가</span>
      </v-btn>
      <!--  버튼: 삭제 모드 변경  -->
      <v-btn
        @click="$store.state.gsSubmit_removeMode = true"
        class="mt-4"
        color="red"
        :disabled="!enabledRemoveButton"
        outlined
        block
      >
        <v-icon>mdi-trash-can</v-icon>
        <span class="text-h6 font-weight-bold">삭제</span>
      </v-btn>
    </template>

    <!--  다이알로그: 이미지 상세보기  -->
    <v-dialog
      v-model="$store.state.gsCommon_dialogShow.img"
      fullscreen
      hide-overlay
      persistent
      scrollable
    >
      <v-card tile>

        <!--  그룹: 컨트롤러  -->
        <v-card-actions class="px-1">
          <!--  버튼: 뒤로가기  -->
          <v-btn
            @click="onClickImgClose"
            class="mr-7"
            icon
          >
            <v-icon x-large>mdi-chevron-left</v-icon>
          </v-btn>

          <v-spacer/>

          <!--  버튼: 삭제  -->
          <v-btn
            @click="onClickRemoveOne"
            color="red"
            text
          >
            삭제
          </v-btn>
        </v-card-actions>

        <v-divider class="grey"/>

        <v-card-text class="pa-2 white-space-pre-wrap grey lighten-3">

          <v-img
            :src="imgDialogData.receipt_src"
            width="100%"
            height="100%"
            contain
          />
        </v-card-text>

        <v-divider class="grey"/>

        <v-card-actions class="pa-4 pb-0">
          <!--  인풋: 메모  -->
          <v-textarea
            @input="onInputMemo('receipt_memo')"
            v-model="imgDialogData.receipt_memo"
            label="메모"
            counter="128"
            maxlength="128"
            type="text"
            rows="1"
            auto-grow
            outlined
            dense
          />
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import env from "../env"
const MAX_LIST_LENGTH = 12

export default {
  name: "SubmitCardFormUpload",

  data: () => ({
    // 업로드 할 이미지 타입
    imgUploadType: '',

    // 이미지 상세보기에 필요한 객체정보
    imgDialogData: {
      receipt_src: '',
      index: -1, // imgList 에 해당하는 인덱스
      receipt_memo: ''
    },

    // 제거할 인덱스 리스트 ( 기준이 되는 배열: imgList )
    toRemoveIndex: [],
  }),

  computed: {
    // 삭제 적용 버튼 클릭
    enabledApplyButton() {
      return !!this.toRemoveIndex.length && !this.gcSubmit_submitting
    },

    // 업로드 버튼 노출 여부
    enabledAddButton() {
      const notRemoveMode = !this.gcSubmit_removeMode
      const notMaximum = 0 < MAX_LIST_LENGTH - this.imgListLength

      return notRemoveMode && notMaximum && !this.gcSubmit_submitting
    },

    // 삭제 버튼 노출 여부
    enabledRemoveButton() {
      return !!this.imgListLength && !this.gcSubmit_submitting
    },

    // 이미지 타입
    imgTypes() {
      return env.IMAGE_TYPE
    },

    // 이미지 리스트
    imgList() {
      const store = this.$store.state
      const uploaded = [...(store.gsCommon_refundUser.refund_image_receipt || [])]
      const toUpload = store.gsSubmit_formData.files_upload.map((data, uploadIndex) => ({
        uploadIndex,
        receipt_src: URL.createObjectURL(data.file),
        receipt_memo: data.receipt_memo
      }))

      return uploaded.concat(toUpload)
    },

    // 이미지 리스트 길이
    imgListLength() {
      const uploadedLength = this.$store.state.gsCommon_refundUser?.refund_image_receipt?.length || 0
      const lengthToUpload = this.$store.state.gsSubmit_formData.files_upload.length

      return uploadedLength + lengthToUpload
    }
  },

  methods: {
    // 삭제하기 위해 채크된 이미지인지 여부
    isCheckImg(i) {
      return this.toRemoveIndex.indexOf(i) !== -1
    },

    // 업로드 이미지 타입 찾기
    getImgType(code) {
      return env.IMAGE_TYPE.find(data => data.value === code)?.text
    },

    // 이미지 클릭시
    onClickImgOpen(data, i) {
      // 삭제 모드 일 경우
      if (this.gcSubmit_removeMode) {
        const target = this.toRemoveIndex.indexOf(i)

        // 리스트에 없는 경우 추가, 있는경우 제거
        target === -1 ?
          this.toRemoveIndex.push(i) : this.toRemoveIndex.splice(target, 1)
      }
      // 일반 클릭일 경우
      else if (this.gmCommon_dialogOpen('img')) {
        this.imgDialogData.receipt_src = data.receipt_src
        this.imgDialogData.receipt_memo = data.receipt_memo
        this.imgDialogData.index = i
      }
    },

    // 이미지 상세보기 팝업 닫기 시
    onClickImgClose() {
      // 작업에 성공할 경우
      if (this.gmCommon_dialogClose('img')) {
        this.imgDialogData.receipt_src = ''
        this.imgDialogData.index = -1
      }
    },

    // 이미지 메모 작성
    onInputMemo(mode) {
      const savedList = this.$store.state.gsCommon_refundUser.refund_image_receipt
      const updateList = this.$store.state.gsSubmit_formData.files_update
      const uploadList = this.$store.state.gsSubmit_formData.files_upload
      const imgListIndex = this.imgDialogData.index
      const id = this.imgList[imgListIndex]?.id
      const uploadIndex = this.imgList[imgListIndex]?.uploadIndex

      // id 가 있을 경우 이미 업로드 된 파일이므로 업데이트 처리
      if (id) {
        const targetIndex = savedList.findIndex(data => data.id === id)

        // 로컬 데이터 업데이트
        savedList[targetIndex][mode] = this.imgDialogData[mode]

        const updateData = { ...savedList[targetIndex] }
        delete updateData.receipt_src
        delete updateData.event_id

        const removeIndex = updateList.findIndex(data => data.id === id)

        // 이전에 업데이트 예약 배열에 저장된 경우 삭제
        removeIndex !== -1 && updateList.splice( removeIndex, 1 )
        updateList.push( updateData )
      }
      // id 가 없을 경우 업로드 처리
      else {
        // 로컬 데이터 업데이트
        uploadList[uploadIndex][mode] = this.imgDialogData[mode]
      }
    },

    // 파일 업로드 버튼 클릭
    onClickAdd() {
      this.gmCommon_dialogOpen('imgType')
    },

    // 파일 업로드 타입 버튼 클릭
    onClickAddType(value) {
      this.imgUploadType = Number(value)
      this.$refs.input.click()
    },

    // 파일 업로드 타입 다이알로그 닫기 클릭
    onClickAddTypeClose() {
      this.gmCommon_dialogClose('imgType')
    },

    // 파일 업로드 이벤트 핸들러
    onInputFiles(event) {
      // 최대로 업로드 가능한 파일 개수
      const limit = MAX_LIST_LENGTH - this.imgListLength
      const files = [...event.target.files].map(file => ({
        file,
        receipt_memo: ''
      }))

      this.imgUploadType = 0

      // 업로드 파일이 없을 경우 동작하지 않고 리턴
      if (!event.target.files.length) { return }

      // 허용 가능한 업로드 개수보다 파일리스트가 클 경우
      // 넘치는 부분 제거
      limit < files.length && files.splice(limit)

      this.$store.state.gsSubmit_formData.files_upload.push(...files)
      this.$router.push({ query: {} })
    },

    // 삭제 모드 취소
    onClickCancel() {
      this.toRemoveIndex.splice(0)
      this.$store.state.gsSubmit_removeMode = false
    },

    // 삭제 진행
    onClickRemove() {
      const store = this.$store.state
      const imgList = [...this.imgList]
      // 내림차순 정렬해서 뒤에서부터 삭제해야 인덱스 밀림 이슈 없음
      const toRemoveIndex = [...this.toRemoveIndex].sort((a, b) => b - a)

      // 지우려고 했던 대기열 값 모두 삭제
      this.toRemoveIndex.splice(0)

      toRemoveIndex.forEach(i => {
        const data = imgList[i]
        // 이미 업로드 된 이미지 일 경우
        if (data.id) {
          store.gsCommon_refundUser.refund_image_receipt.splice(i, 1)
          store.gsSubmit_formData.files_remove.push(data.id)
        }
        // 업로드 예정인 이미지 일 경우
        else {
          store.gsSubmit_formData.files_upload.splice(data.uploadIndex, 1)
        }
      })

      this.$store.state.gsSubmit_removeMode = false
    },

    // 단일 삭제 버튼 클릭 시
    onClickRemoveOne() {
      const it = this
      const callback = function() {
        it.toRemoveIndex.push( it.imgDialogData.index )
        it.$router.replace({ query: {} })

        // 이미지 다이알로그 닫힌 후 삭제 진행
        setTimeout(() => {
          it.onClickRemove()
        })
      }

      this.gmCommon_alertOpen({
        text: '현재 이미지를 삭제할까요?',
        confirmOkText: '삭제',
        confirmOkColor: 'red',
        confirmNoColor: 'default',
        confirm: true,
        callback
      })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../sass/variables"

.c-grid-container
  display: grid
  grid-gap: 8px
  grid-template-columns: repeat(3, 1fr)
  @media (min-width: 420px)
    grid-template-columns: repeat(4, 1fr)
  @media (min-width: 640px)
    grid-template-columns: repeat(5, 1fr)
  >*
    aspect-ratio: 1 // 참고: 최신 속성이므로 광범위하게 적용 안될수도 있음
  .c-img-card
    outline-width: 1px
    outline-style: dashed
    outline-color: map-get($shades, 'black')
    &.checked
      outline-style: solid
      outline-color: map-get($red, 'accent-1')
    .c-check-icon
      position: absolute
      top: 0
      right: 0
      border-radius: 50% !important
      background-color: rgba(255,255,255,.75)

.c-gap
  gap: 20px
</style>
