<template>
  <v-card
    max-width="640"
    min-width="320"
    width="100%"
    tile
  >
    <submit-card-title/>

    <template v-if="gcSubmit_submitted">

      <submit-done/>

    </template>

    <template v-else>

      <submit-card-info/>

      <template v-if="gcCommon_refund.refund_ended">
        <submit-card-ended/>
      </template>

      <template v-else>
        <submit-card-form/>

        <submit-card-action/>
      </template>

    </template>


  </v-card>
</template>

<script>
import SubmitCardTitle from "../components/SubmitCardTitle"
import SubmitCardInfo from "../components/SubmitCardInfo"
import SubmitCardForm from "../components/SubmitCardForm"
import SubmitCardAction from "../components/SubmitCardAction"
import SubmitCardEnded from "../components/SubmitCardEnded"
import SubmitDone from "../components/SubmitDone"

export default {
  name: 'Submit',

  components: {
    SubmitCardTitle,
    SubmitCardInfo,
    SubmitCardForm,
    SubmitCardAction,
    SubmitCardEnded,
    SubmitDone,
  },

  mounted() {
    this.gmSubmit_init()
  },
}
</script>