<template>
  <v-sheet
    class="px-2 py-8"
    max-width="640"
  >

    <!--  애러: 상태 코드  -->
    <h1 class="text-h3 font-weight-bold mb-4">
      {{ gcCommon_infoError.code }}
    </h1>

    <!--  애러: 상세 내용  -->
    <p class="subtitle-2">
      {{ gcCommon_infoError.message }}
    </p>

  </v-sheet>
</template>

<script>
export default {
  name: "Error"
}
</script>