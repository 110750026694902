import Vue from "vue"

import methodsCommon from "./methodsCommon"
import methodsAPI from "./methodsAPI"
import methodsSubmit from "./methodsSubmit"

import computedCommon from "./computedCommon"
import computedSubmit from "./computedSubmit"

const mixin = {
  methods: {
    ...methodsCommon,
    ...methodsAPI,
    ...methodsSubmit,
  },

  computed: {
    ...computedCommon,
    ...computedSubmit,
  }
}

Vue.mixin( mixin )

export default mixin