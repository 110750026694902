export default {
  DEV_MODE: process.env.NODE_ENV !== 'production',
  API_BASE_URL: '//api-dev.ebiseo.com',
  BANK_LIST: [
    {
      text: '선택',
      value: '',
      src: ''
    },
    {
      text: '우리',
      value: '020',
      src: require('./assets/bank-icons/bank-020.png')
    },
    {
      text: '국민',
      value: '004',
      src: require('./assets/bank-icons/bank-004.png')
    },
    {
      text: '신한',
      value: '088',
      src: require('./assets/bank-icons/bank-088.png')
    },
    {
      text: 'KEB하나',
      value: '081',
      src: require('./assets/bank-icons/bank-081.png')
    },
    {
      text: 'IBK기업',
      value: '003',
      src: require('./assets/bank-icons/bank-003.png')
    },
    {
      text: 'NH농협',
      value: '011',
      src: require('./assets/bank-icons/bank-011.png')
    },
    {
      text: '우체국',
      value: '071',
      src: require('./assets/bank-icons/bank-071.png')
    },
    {
      text: '산업',
      value: '002',
      src: require('./assets/bank-icons/bank-002.png')
    },
    {
      text: '새마을',
      value: '045',
      src: require('./assets/bank-icons/bank-045.png')
    },
    {
      text: '부산',
      value: '032',
      src: require('./assets/bank-icons/bank-032.png')
    },
    {
      text: '대구',
      value: '031',
      src: require('./assets/bank-icons/bank-031.png')
    },
    {
      text: '광주',
      value: '034',
      src: require('./assets/bank-icons/bank-034.png')
    },
    {
      text: '경남',
      value: '039',
      src: require('./assets/bank-icons/bank-039.png')
    },
    {
      text: '전북',
      value: '037',
      src: require('./assets/bank-icons/bank-037.png')
    },
    {
      text: '제주',
      value: '035',
      src: require('./assets/bank-icons/bank-035.png')
    },
    {
      text: 'SC제일',
      value: '023',
      src: require('./assets/bank-icons/bank-023.png')
    },
    {
      text: '씨티',
      value: '027',
      src: require('./assets/bank-icons/bank-027.png')
    },
    {
      text: '신협',
      value: '048',
      src: require('./assets/bank-icons/bank-048.png')
    },
    {
      text: '수협',
      value: '007',
      src: require('./assets/bank-icons/bank-007.png')
    },
    {
      text: '카카오뱅크',
      value: '090',
      src: require('./assets/bank-icons/bank-090.png')
    },
    {
      text: 'K뱅크',
      value: '089',
      src: require('./assets/bank-icons/bank-089.png')
    },
    {
      text: '직접입력',
      value: 'etc',
      src: ''
    }
  ]
}
