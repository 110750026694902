<template>
  <v-card-actions class="px-4 pt-0 pb-16 flex-column align-stretch">
    <v-divider class="mb-7"/>

    <v-btn
      @click="onClickSubmit"
      :loading="gcSubmit_submitting"
      :disabled="gcSubmit_refundSubmitState === 0"
      :dark="gcSubmit_refundSubmitState !== 0"
      color="blue"
      type="submit"
      depressed
      x-large
      block
    >
      <v-icon class="ml-n5 mr-2">mdi-send</v-icon>
      제출하기
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: "SubmitCardAction",

  methods: {
    async onClickSubmit() {
      const form = this.gcSubmit_form.ref
      const valid = form.validate()
      const validSign = this.$store.state.gsSubmit_formData.refund_submit_state === 1 ?
        this.gsSubmit_signValid() : true

      // 유효성 검사
      valid && validSign ?
        // 적격: API 호출
        await this.gmAPI_submitRefund() :
        // 부적격: 타겟 찾은 후 포커싱 (비동기 처리 해야 타겟 찾을 수 있음)
        setTimeout(() => document.querySelector('.v-input.error--text')?.scrollIntoView())
    }
  }
}
</script>

<style scoped>

</style>
